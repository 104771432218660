<template>
    <el-dialog
            :title="title"
            :close-on-click-modal="false"
            append-to-body
            :visible.sync="visible">
        <el-form size="small" :model="inputForm" :rules="dataRule" v-loading="loading"  ref="inputForm" @keyup.enter.native="doSubmit()"
                 label-width="150px" @submit.native.prevent>
            <el-form-item label="栏目名称：" prop="columnName">
                <el-input v-model.trim="inputForm.columnName" placeholder="请输入" maxlength="5"></el-input>
            </el-form-item>
            <el-form-item label="排序号：" prop="columnSort">
                <el-input-number  :step="1" :min="1" :max="9999" v-model="inputForm.columnSort" placeholder="排序号"></el-input-number>
            </el-form-item>
            <el-form-item label="是否显示：" prop="columnShow">
                <el-switch v-model="inputForm.columnShow" :active-value="0" :inactive-value="1"></el-switch>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="visible = false">取消</el-button>
      <el-button size="small" type="primary" @click="doSubmit()" v-noMoreClick>保存</el-button>
    </span>
    </el-dialog>
</template>

<script>
    export default {
        data () {
            return {
                title:"新增",
                visible: false,
                loading: false,
                inputForm: {
                    id:"",
                    columnSort: 1,
                    columnName: '',
                    columnShow: 0,
                },
                dataRule: {
                    columnName: [
                        {required: true, message: '栏目名称', trigger: 'blur'}
                    ],
                    columnSort: [
                        {required: true, message: '排序号不能为空', trigger: 'blur'}
                    ]
                },
                method:""
            }
        },
        methods: {
            init (method, row) {
                this.method = method
                this.visible = true
                this.loading = false
                this.inputForm.id = ""
                this.title = "新增"
                if(method == "add"){
                    this.inputForm.informationId = row
                }
                this.$nextTick(() => {
                    this.$refs['inputForm'].resetFields()
                    if (method === 'edit') { // 修改
                        this.title = "修改"
                        this.inputForm = this.recover(this.inputForm, row)
                    }
                })
            },
            // 表单提交
            doSubmit () {
                this.$refs['inputForm'].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        let api = this.api.digital.exhibitionTeamSave
                        let type = "post"
                        if(this.method == "edit"){
                            api = this.api.rcPage.updateHomeColumn
                            type = "post"
                        }
                        this.$axios(api, this.inputForm, type).then(data => {
                            this.loading = false
                            if (data && data.status) {
                                this.$message.success(data.data)
                                this.visible = false
                                this.$emit('refreshDataList')
                            }
                        })
                    }
                })
            }
        }
    }
</script>
